import { Menu, Popover, Transition } from "@headlessui/react";
import {
    classNames,
    isPublicPage,
    userHasClientRole,
    userRole,
} from "@/utilities";
import { Fragment } from "react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { homeNavigation } from "@/constants";
import ProfilePicture from "@/components/candidates/ProfilePicture";
import AccountMenuLogo from "@/components/commonlayout/AccountMenuLogo";
import CreditsRemaining from "@/components/commonlayout/CreditsRemaining";
import { useRouter } from "next/router";
import { useGetOrganizationDataQuery } from "@/services/modules/organization";

const AccountMenu = ({
    size,
    user,
    logout,
    module,
    check,
    onboarding = false,
    isEngineer,
    userRoles,
}) => {
    const { userIsCompany } = userRole(user);
    const router = useRouter();
    const { data } = useGetOrganizationDataQuery(
        { id: user?.organization?.id },
        { skip: !user }
    );
    const organization = data?.organization;

    const showTeamAndNotifications = userRoles && userHasClientRole(userRoles);

    const showButtons = !isPublicPage(router)
        ? Boolean(userIsCompany && organization)
        : true;
    return (
        <>
            {size === "large" && (
                <>
                    {user ? (
                        <div className="hidden lg:ml-4 lg:flex lg:items-center">
                            {userIsCompany && organization && (
                                <CreditsRemaining user={user} />
                            )}
                            <Menu
                                as="div"
                                className="ml-4 relative flex-shrink-0"
                            >
                                <div>
                                    <Menu.Button
                                        className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200"
                                        data-test-id="navbar-account-dropdown-button"
                                    >
                                        <span className="sr-only">
                                            Open user menu
                                        </span>
                                        <AccountMenuLogo
                                            user={user}
                                            organization={organization}
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 w-max">
                                        {!onboarding && (
                                            <Menu.Item key={"account"}>
                                                <>
                                                    <Link
                                                        href={`/${module}/account`}
                                                    >
                                                        <a data-test-id="account-page">
                                                            <div className="px-4 py-2 text-sm text-gray-700 flex hover:bg-gray-100">
                                                                <ProfilePicture
                                                                    user={user}
                                                                    size={12}
                                                                />
                                                                <div className="px-4 flex flex-col justify-center">
                                                                    <div className="text-sm font-bold flex">
                                                                        {
                                                                            user?.name
                                                                        }
                                                                        {module ===
                                                                            "company" &&
                                                                        organization?.name !==
                                                                            undefined
                                                                            ? ` (${organization?.name})`
                                                                            : ""}
                                                                    </div>
                                                                    <div className="flex items-center">
                                                                        {
                                                                            user?.email
                                                                        }
                                                                        {user?.email_verified_at ? (
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faCircleCheck
                                                                                }
                                                                                className="flex-shrink-0 h-5 w-5 text-teal-500 pl-1"
                                                                                aria-hidden="true"
                                                                                title="Verified"
                                                                            />
                                                                        ) : (
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faCircleExclamation
                                                                                }
                                                                                className="flex-shrink-0 h-5 w-5 text-red-500 pl-1"
                                                                                aria-hidden="true"
                                                                                title="Action Needed"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </>
                                            </Menu.Item>
                                        )}
                                        {!check && (
                                            <Menu.Item
                                                key={
                                                    module === "company"
                                                        ? "dashboard"
                                                        : "profile"
                                                }
                                            >
                                                <>
                                                    <Link
                                                        href={`/${module}/${
                                                            module === "company"
                                                                ? "dashboard"
                                                                : "profile"
                                                        }`}
                                                    >
                                                        <a>
                                                            <div className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100">
                                                                {module ===
                                                                "company"
                                                                    ? "Dashboard"
                                                                    : "Profile"}
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </>
                                            </Menu.Item>
                                        )}
                                        {module === "company" && (
                                            <>
                                                <Menu.Item key={"company"}>
                                                    <>
                                                        <Link
                                                            href={`/${module}/company`}
                                                        >
                                                            <a>
                                                                <div className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100">
                                                                    Company
                                                                </div>
                                                            </a>
                                                        </Link>
                                                    </>
                                                </Menu.Item>
                                                {showTeamAndNotifications && (
                                                    <>
                                                        <Menu.Item key={"team"}>
                                                            <>
                                                                <Link
                                                                    href={`/${module}/team`}
                                                                >
                                                                    <a>
                                                                        <div className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100">
                                                                            Team
                                                                        </div>
                                                                    </a>
                                                                </Link>
                                                            </>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key={
                                                                "notifications"
                                                            }
                                                        >
                                                            <>
                                                                <Link
                                                                    href={`/${module}/notifications`}
                                                                >
                                                                    <a>
                                                                        <div className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100">
                                                                            Notifications
                                                                        </div>
                                                                    </a>
                                                                </Link>
                                                            </>
                                                        </Menu.Item>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <Menu.Item key={"logout"}>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100"
                                                            : "",
                                                        "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                                    )}
                                                    onClick={logout}
                                                    data-test-id="logout"
                                                >
                                                    Logout
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    ) : (
                        <div className="flex items-center gap-x-5 md:gap-x-8">
                            {showButtons && (
                                <div className="hidden md:block md:ml-6 md:space-x-6">
                                    <Link href="/login">
                                        <a
                                            className="font-medium text-primary-600 hover:text-primary-500"
                                            type="button"
                                            data-test-id="login"
                                        >
                                            Log in
                                        </a>
                                    </Link>
                                    <div className="hidden md:inline-block md:ml-6 md:space-x-6">
                                        <Link href="/signup">
                                            <a
                                                className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary-600 text-white hover:text-slate-100 hover:bg-primary-500 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600"
                                                type="button"
                                                data-test-id="sign-up"
                                            >
                                                <span>Sign up</span>
                                            </a>
                                        </Link>
                                        {!isEngineer &&
                                            ![
                                                "/404",
                                                "/about",
                                                "/demo",
                                                "/engineers",
                                                "/share/[shareCode]",
                                                "/privacy-policy",
                                                "/cookie-policy",
                                                "/terms-and-conditions",
                                            ].includes(router?.pathname) && (
                                                <Link href="/demo">
                                                    <a
                                                        target="_blank"
                                                        className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-400 text-white hover:text-slate-100 hover:bg-primary-500 active:bg-red-600 active:text-red-100 focus-visible:outline-red-400"
                                                        type="button"
                                                        data-test-id="book-a-demo"
                                                    >
                                                        <span>Book a demo</span>
                                                    </a>
                                                </Link>
                                            )}
                                    </div>

                                    <div className="-mr-1 md:hidden">
                                        <div>
                                            <button
                                                className="relative z-10 flex h-8 w-8 items-center justify-center [&amp;:not(:focus-visible)]:focus:outline-none"
                                                aria-label="Toggle Navigation"
                                                id="headlessui-popover-button-:R1p6:"
                                                type="button"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                                                    fill="none"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                >
                                                    <path
                                                        d="M0 1H14M0 7H14M0 13H14"
                                                        className="origin-center transition"
                                                    />
                                                    <path
                                                        d="M2 2L12 12M12 2L2 12"
                                                        className="origin-center transition scale-90 opacity-0"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {size === "small" && (
                <>
                    {!user ? (
                        <div className="bg-white">
                            <Link href="/login">
                                <a
                                    className="block w-full px-5 py-3 text-center font-medium text-primary-600 bg-gray-50 hover:bg-gray-100"
                                    data-test-id="login"
                                >
                                    Log in
                                </a>
                            </Link>
                            <Link href="/signup">
                                <a
                                    className="block w-full px-5 py-3 mb-2 text-center font-medium rounded-full bg-primary-600 text-white hover:text-slate-100 hover:bg-primary-500 active:bg-primary-800 active:text-primary-100"
                                    type="button"
                                    data-test-id="sign-up"
                                >
                                    Sign up
                                </a>
                            </Link>
                            {!isEngineer &&
                                ![
                                    "/404",
                                    "/about",
                                    "/demo",
                                    "/engineers",
                                    "/share/[shareCode]",
                                    "/privacy-policy",
                                    "/cookie-policy",
                                    "/terms-and-conditions",
                                ].includes(router?.pathname) && (
                                    <Link href="/demo">
                                        <a
                                            target="_blank"
                                            className="block w-full px-5 py-3 mb-2 text-center font-medium rounded-full bg-red-400 text-white hover:text-slate-100 hover:bg-primary-500 active:bg-red-600 active:text-red-100"
                                            type="button"
                                            data-test-id="book-a-demo"
                                        >
                                            Book a demo
                                        </a>
                                    </Link>
                                )}
                        </div>
                    ) : (
                        <Popover.Panel
                            focus
                            className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
                                <div className="pt-3 pb-2">
                                    <div className="flex items-center justify-between px-5">
                                        <AccountMenuLogo
                                            user={user}
                                            organization={organization}
                                        />
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                                <span className="sr-only">
                                                    Close menu
                                                </span>
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4 pb-2">
                                    <div className="flex items-center px-5">
                                        <ProfilePicture user={user} size={12} />
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">
                                                {user?.name}
                                            </div>
                                            <div className="text-sm font-medium text-gray-500">
                                                {user?.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {homeNavigation.map((item) => {
                                            if (item.children) {
                                                return (
                                                    <>
                                                        <Link
                                                            key={item.name}
                                                            href={item.href}
                                                        >
                                                            <a
                                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                data-test-id={`${
                                                                    item - name
                                                                }-page`}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        </Link>
                                                        {item.children.map(
                                                            (child) => {
                                                                return (
                                                                    <Link
                                                                        key={
                                                                            child.name
                                                                        }
                                                                        href={
                                                                            child.href
                                                                        }
                                                                    >
                                                                        <a
                                                                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                            data-test-id={`${
                                                                                child -
                                                                                name
                                                                            }-page`}
                                                                        >
                                                                            {
                                                                                child.name
                                                                            }
                                                                        </a>
                                                                    </Link>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                );
                                            } else
                                                return (
                                                    <Link
                                                        key={item.name}
                                                        href={item.href}
                                                    >
                                                        <a
                                                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                            data-test-id={`${
                                                                item - name
                                                            }-page`}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    </Link>
                                                );
                                        })}
                                        {!check && (
                                            <Link
                                                key={
                                                    module === "company"
                                                        ? "dashboard"
                                                        : "profile"
                                                }
                                                href={`/${module}/${
                                                    module === "company"
                                                        ? "dashboard"
                                                        : "profile"
                                                }`}
                                            >
                                                <a className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                                                    {module === "company"
                                                        ? "Dashboard"
                                                        : "Profile"}
                                                </a>
                                            </Link>
                                        )}
                                        <Link
                                            key={"account"}
                                            href={`/${module}/account`}
                                        >
                                            <a
                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                data-test-id={"account-page"}
                                            >
                                                Account
                                            </a>
                                        </Link>
                                        {module === "company" && (
                                            <>
                                                <Link
                                                    key={"company"}
                                                    href={`/${module}/company`}
                                                >
                                                    <a
                                                        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                        data-test-id={
                                                            "company-page"
                                                        }
                                                    >
                                                        Company
                                                    </a>
                                                </Link>
                                                {showTeamAndNotifications && (
                                                    <>
                                                        <Link
                                                            key={"team"}
                                                            href={`/${module}/team`}
                                                        >
                                                            <a
                                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                data-test-id={
                                                                    "team-page"
                                                                }
                                                            >
                                                                Team
                                                            </a>
                                                        </Link>
                                                        <Link
                                                            key={
                                                                "notifications"
                                                            }
                                                            href={`/${module}/notifications`}
                                                        >
                                                            <a
                                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                data-test-id={
                                                                    "notifications-page"
                                                                }
                                                            >
                                                                Notifications
                                                            </a>
                                                        </Link>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <button
                                            key={"logout"}
                                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full text-left"
                                            onClick={logout}
                                        >
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    )}
                </>
            )}
        </>
    );
};

export default AccountMenu;
